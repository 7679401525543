var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-input-switch-wrapper"},[_c('div',{staticClass:"base-input-switch",class:{
      'is-focus': _vm.focus,
      'is-disabled': _vm.isLocked,
      'size-sm': _vm.size === 'sm',
      'size-md': _vm.size === 'md',
      'size-lg': _vm.size === 'lg',
      'switch-enabled' : _vm.rangeValue === 1,
    },attrs:{"index":_vm.value},on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticStyle:{"pointer-events":"none"}},[_c('span',{class:[
        'handle',
        _vm.rangeValue === 1 ? 'handle-on' : 'handle-off'
      ]},[(_vm.isLocked)?_c('icon',{attrs:{"name":"lock"}}):(_vm.icon)?_c('icon',{attrs:{"name":_vm.icon}}):_vm._t("default")],2)]),_c('input',{ref:"input",attrs:{"type":"range","tabIndex":_vm.tabIndex,"step":1,"max":1,"min":0,"disabled":_vm.isLocked},domProps:{"value":_vm.rangeValue},on:{"focus":function () { return _vm.setFocus(true); },"focusout":function () { return _vm.setFocus(false); },"mouseup":_vm.onClick}})])])}
var staticRenderFns = []

export { render, staticRenderFns }